export type some = { [key: string]: any };

export const SS_TOKEN = 'token';

export const BE_DATE_FORMAT = 'YYYY-MM-DD';
export const FE_DATE_FORMAT = 'DD/MM/YYYY';
export const INS_DATE_FORMAT = 'YYYYMMDD';
export const INS_DATE_TIME_FORMAT = 'YYYYMMDDHHmm';
export const FE_TIME_FORMAT = 'HH:mm';
export const FE_TIME_DATE_FORMAT = `${FE_TIME_FORMAT} ${FE_DATE_FORMAT}`;
export const FE_TIME_DATE_FORMAT_DASH = `${FE_TIME_FORMAT} - ${FE_DATE_FORMAT}`;
export const FE_DATE_TIME_FORMAT = `${FE_DATE_FORMAT} ${FE_TIME_FORMAT}`;
export const DATE_TIME_FORMAT = `YYYY-MM-DDTHH:mm:ssZ`;
export const BE_DATE_TIME_FORMAT = `YYYY-MM-DDTHH:mm:ssZ`;
export const YEAR_FORMAT = 'YYYY';
export const FE_TIME_ZONE_FORMAT = 'HH:mm:ssZ';

export const MONTH_YEAR_FORMAT = 'M/yyyy';
export const QUARTER_YEAR_FORMAT = 'Q/yyyy';
export const WEEK_NUMBER_YEAR_FORMAT = 'W/yyyy';

export const BILLING_STATUS = {
  billed: 'billed',
  billable: 'billable',
  planned: 'planned',
  notBillable: 'not-billable',
};

export const TASK_STATUS = {
  accepted: 'accepted',
  rejected: 'rejected',
  requested: 'requested',
  completed: 'completed',
};
export const ACCOUNT_STATUS = {
  active: 'active',
  inactive: 'inactive',
};
export const ENCOUNTER_STATUS = {
  arrived: 'arrived',
  inProgress: 'in-progress',
  finished: 'finished',
};

export const STATUS_ENCOUNTER_OPTIONS = [
  {
    value: 'in-progress',
    label: 'status.progress',
  },
  {
    value: 'arrived',
    label: 'status.arrived',
  },
  {
    value: 'finished',
    label: 'status.finished',
  },
];

export const PAYMENT_CATEGORY = {
  bhyt: '0',
  thuPhi: '1',
  mien: '2',
  khac: '3',
};

export const DIAGNOSTIC_REPORT_STATUS = {
  final: 'final',
  registered: 'registered',
};
export const DIAGNOSTIC_REPORT_STATUS_OPTIONS = [
  {
    value: 'registered',
    label: 'status.register',
  },
  {
    value: 'final',
    label: 'status.finished',
  },
];
export const DIAGNOSTIC_REPORT_STATUS_OPTIONS_LAB = [
  {
    value: 'REQUESTED',
    label: 'status.register',
  },
  {
    value: 'FINISHED',
    label: 'status.finished',
  },
];
export const SERVICE_CATEGORY_LIST = {
  insured: 'correct-route-social-security-healthcare-services',
  uninsured: 'not-social-security-healthcare-services',
};

export const INDICATION_CATEGORY_LIST = {
  insured: 'correct-route-social-security-tests',
  uninsured: 'not-social-security-tests',
};
export const PROCEDURE_CATEGORY_LIST = {
  insured: 'correct-route-social-security-surgical-procedure',
  uninsured: 'not-social-security-surgical-procedure',
};
export const OTHER_SERVICE_CATEGORY_LIST = {
  insured: 'correct-route-social-security-other-service',
  uninsured: 'not-social-security-other-service',
};

export const VALUE_INTERPRETATION = {
  normal: 'Normal',
  abnormal: 'Abnormal',
  high: 'High',
  low: 'Low',
};

export const VALUE_EVALUATE = {
  normal: 'NORMAL',
  abnormal: 'NO_NORMAL',
  high: 'HIGH',
  low: 'LOW',
};

export const LAB_TYPE_RESULT_CORE_SERVICE = {
  string: 'DATA_INPUT',
  list: 'LIST',
  quantity: 'STATISTICS',
};

export const LAB_TYPE_RESULT = {
  string: 'string',
  list: 'CodeableConcept',
  quantity: 'Quantity',
};

export const SS_PAY_URL = 'http://hl7.org/fhir/StructureDefinition/unit-social-security-payment';
export const PATIENT_PAY_URL = 'http://hl7.org/fhir/StructureDefinition/unit-coinsurance-payment';
export const PATIENT_DE_URL = 'http://hl7.org/fhir/StructureDefinition/unit-deductible-payment';
export const BURDEN_RATE_URL = 'http://hl7.org/fhir/StructureDefinition/social-security-burden-rate';
export const VAT_URL = 'http://hl7.org/fhir/StructureDefinition/VAT-rate';
export const DISCOUNT_AMOUNT_URL = 'http://hl7.org/fhir/StructureDefinition/discount-amount';
export const DISCOUNT_RATE_URL = 'http://hl7.org/fhir/StructureDefinition/discount-rate';
export const ALL_DISCOUNT_RATE_URL = 'http://hl7.org/fhir/StructureDefinition/discount-rate-for-all';

export const CHARGE_ITEM_TYPES = {
  healthcareService: 'healthcareService',
  procedure: 'procedure',
  otherService: 'otherService',
  labTest: 'labIndication',
  imageTest: 'imageIndication',
  supply: 'supply',
  drug: 'drug',
};

export const ANESTHESIA_OPTIONS = [
  { value: 'none', label: 'procedure.none' },
  { value: '386761002', label: 'procedure.local' },
  { value: '50697003', label: 'procedure.general' },
];

// Hapi
export const ORDER_TYPE = {
  asc: 'asc',
  desc: 'desc',
};

export const ORDER_BE = {
  asc: 'ASC',
  desc: 'DESC',
};

export const CODE_SYSTEM = {
  department: 'DEPARTMENT_CODE',
  country: 'COUNTRY',
  countryEn: 'COUNTRY_EN',
  paymentType: 'PAYMENT_TYPE',
  medicationRoute: 'MEDICATION_INTENDED_ROUTE',
  dentalDiagnosis: 'DENTAL_DIAGNOSIS',
  currency: 'CURRENCY',
  dentalIllness: 'DENTAL_ILLNESS',
  medicalIllness: 'MEDICAL_ILLNESS',
  ICD10: 'ICD_10',
  MEDICATION_INTENDED_ROUTE: 'MEDICATION_INTENDED_ROUTE',
} as const;

export const SYSTEM_CATEGORY_SCOPE = {
  healthCheck: 'HEALTH_CHECK_SERVICE',
  laboratory: 'LABORATORY_SERVICE',
  radiology: 'RADIOLOGY_SERVICE',
  surgical: 'SURGICAL_SERVICE',
  other: 'OTHER_SERVICE',
  medicalSupplies: 'MEDICAL_SUPPLIES',
  physicsRoom: 'PHYSICS_ROOM',
  medicalEquipment: 'MEDICAL_EQUIPMENT',
  therapy: 'THERAPY_SERVICE',
} as const;

export const SYSTEM_CATEGORY_SCOPE_LABEL = [
  { value: 'HEALTH_CHECK_SERVICE', label: 'systemCategory.healthCheck' },
  { value: 'LABORATORY_SERVICE', label: 'systemCategory.laboratory' },
  { value: 'RADIOLOGY_SERVICE', label: 'systemCategory.radiology' },
  { value: 'SURGICAL_SERVICE', label: 'systemCategory.surgical' },
  { value: 'OTHER_SERVICE', label: 'systemCategory.other' },
  { value: 'MEDICAL_SUPPLIES', label: 'systemCategory.medicalSupplies' },
  { value: 'PHYSICS_ROOM', label: 'systemCategory.physicsRoom' },
  { value: 'MEDICAL_EQUIPMENT', label: 'systemCategory.medicalEquipment' },
  { value: 'THERAPY_SERVICE', label: 'systemCategory.therapy' },
];

export const SYSTEM_CATEGORY_TYPE = {
  priceList: 'PRICE_LIST',
  group: 'GROUP',
} as const;

export const PHYSICS_ROOM_TYPE = {
  examRooms: { code: 'DX', name: 'Diagnostics or therapeutics unit' },
  labRooms: { code: 'MBL', name: 'medical laboratory' },
  imageRooms: { code: 'HRAD', name: 'radiology unit' },
  procedureRooms: { code: '225738002', name: 'Operating room' },
};

export const MEDICAL_EQUIPMENT_TYPE = {
  laboratory: { code: '705215000', name: 'Laboratory instruments and equipment' },
  radiology: { code: '314789007', name: 'Diagnostic imaging equipment' },
};

export const PATIENT_ADDRESS_TYPE = {
  home: 'HOME',
  work: 'WORK',
};

export const INDICATION_REQUEST_STATUS = {
  REQUESTED: {
    value: 'REQUESTED',
    label: 'status.register',
    color: '#0052E0',
  },
  FINISHED: {
    value: 'FINISHED',
    label: 'status.finished',
    color: '#43A047',
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'therapyIndication.status.cancelled',
    color: '#E53935',
  },
  WAIT: {
    value: 'WAIT',
    label: 'therapyIndication.status.wait',
    color: '#FFB300',
  },
  DELETED: {
    value: 'DELETED',
    label: 'therapyIndication.status.deleted',
    color: '#000',
  },
} as const;

export const THERAPY_SESSION_STATUS = {
  PROCESSING: {
    value: 'PROCESSING',
    label: 'status.progress',
    color: '#ffb300',
  },
  FINISHED: {
    value: 'FINISHED',
    label: 'status.finished',
    color: '#43A047',
  },
} as const;

export const INDICATION_REQUEST_STATUS_OPTIONS = [
  {
    value: 'WAIT',
    label: 'therapyIndication.status.wait',
    color: '#FFB300',
  },
  {
    value: 'REQUESTED',
    label: 'status.register',
    color: '#0052E0',
  },
  {
    value: 'FINISHED',
    label: 'status.finished',
    color: '#43A047',
  },
];

export const INDICATION_REQUEST_STATUS_OPTIONS_CUSTOM = [
  {
    value: 'REQUESTED',
    label: 'status.register',
    color: '#0052E0',
  },
  {
    value: 'FINISHED',
    label: 'status.finished',
    color: '#43A047',
  },
];

export const INDICATION_REQUEST_TYPE = {
  THERAPY_SESSION: 'THERAPY_SESSION',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL_EXAM: 'DENTAL_EXAM',
} as const;

export const PAYMENT_PLAN = {
  FEE: {
    value: 'FEE',
    label: 'common.label.fee',
  },
  FREE: {
    value: 'FREE',
    label: 'common.label.free',
  },
  OTHER: {
    value: 'OTHER',
    label: 'common.label.other',
  },
};

export const THERAPY_PROCEDURES_ANESTHESIA_OPTIONS = [
  { value: 'NOT_AVAILABLE', label: 'procedure.none' },
  { value: 'ANESTHETIZE', label: 'procedure.local' },
  { value: 'ANESTHESIA', label: 'procedure.general' },
];

export const THERAPY_PROCEDURES_ATTEND_PERSON_OPTIONS = [
  { value: 'MAIN_PERFORMER', label: 'therapy.procedures.label.mission.main_perform' },
  { value: 'ANESTHESIOLOGIST', label: 'therapy.procedures.label.mission.anesthesiologist' },
  { value: 'ASSISTANT', label: 'therapy.procedures.label.mission.assistant' },
  { value: 'HELPER', label: 'therapy.procedures.label.mission.helper' },
];

export const ENCOUNTER_PAYMENT_PLAN = {
  '1': {
    value: 'FEE',
    label: 'common.label.fee',
  },
  '2': {
    value: 'FREE',
    label: 'common.label.free',
  },
  '3': {
    value: 'OTHER',
    label: 'common.label.other',
  },
};

export const DENTAL_EXAM_STATUS = {
  ARRIVED: { value: 'ARRIVED', label: 'dental.management.status.arrived', sx: 'primary.main' },
  PROCESSING: { value: 'PROCESSING', label: 'dental.management.status.processing', sx: 'warning.main' },
  FINISHED: { value: 'FINISHED', label: 'dental.management.status.finished', sx: 'success.main' },
  CANCELLED: { value: 'CANCELLED', label: 'dental.management.status.canceled', sx: 'error.main' },
} as const;

export const DENTAL_DIAGNOSIS_BASIC_TYPE = {
  DENTAL: { value: 'DENTAL' },
  MEDICAL_HISTORY: { value: 'MEDICAL_HISTORY' },
};

export const DENTAL_ILLNESS_OPTION = [
  { value: 'Đau răng', label: 'Đau răng' },
  { value: 'Răng nhiễm màu', label: 'Răng nhiễm màu' },
  { value: 'Sâu răng', label: 'Sâu răng' },
  { value: 'Răng mẻ', label: 'Răng mẻ' },
  { value: 'Răng nứt vỡ', label: 'Răng nứt vỡ' },
  { value: 'Răng mọc ngầm', label: 'Răng mọc ngầm' },
  { value: 'Răng nhạy cảm', label: 'Răng nhạy cảm' },
  { value: 'Tật thừa răng', label: 'Tật thừa răng' },
  { value: 'Răng xô lệch', label: 'Răng xô lệch' },
  { value: 'Răng thưa', label: 'Răng thưa' },
  { value: 'Vấn đề về nướu', label: 'Vấn đề về nướu' },
  { value: 'Nghiến răng', label: 'Nghiến răng' },
  { value: 'Răng khôn', label: 'Răng khôn' },
  { value: 'Răng quá khít', label: 'Răng quá khít' },
  { value: 'Hôi miệng', label: 'Hôi miệng' },
  { value: 'Lở loét miệng', label: 'Lở loét miệng' },
  { value: 'Mòn răng do axit', label: 'Mòn răng do axit' },
  { value: 'Nụ cười kém thẩm mỹ', label: 'Nụ cười kém thẩm mỹ' },
  { value: 'Các bệnh nhiễm vi khuẩn, virus, nấm', label: 'Các bệnh nhiễm vi khuẩn, virus, nấm' },
  { value: 'Chấn thương', label: 'Chấn thương' },
  { value: 'Ung thư vùng miệng', label: 'Ung thư vùng miệng' },
  { value: 'Viêm lợi', label: 'Viêm lợi' },
  { value: 'Viêm nha chu', label: 'Viêm nha chu' },
  { value: 'Viêm tủy', label: 'Viêm tủy' },
  { value: 'Vôi hóa tuyến nước bọt', label: 'Vôi hóa tuyến nước bọt' },
  { value: 'Rối loạn phát triển răng và mọc răng', label: 'Rối loạn phát triển răng và mọc răng' },
  { value: 'Không đủ răng', label: 'Không đủ răng' },
  { value: 'Răng thừa', label: 'Răng thừa' },
  { value: 'Bất thường kích thước và hình dạng răng', label: 'Bất thường kích thước và hình dạng răng' },
  { value: 'Răng lốm đốm', label: 'Răng lốm đốm' },
  { value: 'Rối loạn tạo răng', label: 'Rối loạn tạo răng' },
  {
    value: 'Rối loạn di truyền cấu trúc răng, không phân loại nơi khác',
    label: 'Rối loạn di truyền cấu trúc răng, không phân loại nơi khác',
  },
  { value: 'Rối loạn mọc răng', label: 'Rối loạn mọc răng' },
  { value: 'Hội chứng mọc răng', label: 'Hội chứng mọc răng' },
  { value: 'Rối loạn khác về phát triển răng', label: 'Rối loạn khác về phát triển răng' },
  { value: 'Rối loạn phát triển răng, không đặc hiệu', label: 'Rối loạn phát triển răng, không đặc hiệu' },
  { value: 'Răng mọc kẹt và răng ngầm', label: 'Răng mọc kẹt và răng ngầm' },
  { value: 'Răng ngầm', label: 'Răng ngầm' },
  { value: 'Răng mọc kẹt', label: 'Răng mọc kẹt' },
  { value: 'Sâu răng', label: 'Sâu răng' },
  { value: 'Sâu giới hạn ở men', label: 'Sâu giới hạn ở men' },
  { value: 'Sâu ngà', label: 'Sâu ngà' },
  { value: 'Sâu chất xương', label: 'Sâu chất xương' },
  { value: 'Sâu răng ngưng tiến triển', label: 'Sâu răng ngưng tiến triển' },
  { value: 'Hủy răng', label: 'Hủy răng' },
  { value: 'Sâu răng với hở tủy', label: 'Sâu răng với hở tủy' },
  { value: 'Sâu răng khác', label: 'Sâu răng khác' },
  { value: 'Sâu răng, không đặc hiệu', label: 'Sâu răng, không đặc hiệu' },
  { value: 'Bệnh mô cứng khác của răng', label: 'Bệnh mô cứng khác của răng' },
  { value: 'Mòn răng quá mức', label: 'Mòn răng quá mức' },
  { value: 'Mòn răng', label: 'Mòn răng' },
  { value: 'Tiêu răng bệnh lý', label: 'Tiêu răng bệnh lý' },
  { value: 'Tăng sinh chất răng', label: 'Tăng sinh chất răng' },
  { value: 'Cứng khớp răng', label: 'Cứng khớp răng' },
  { value: 'Cặn lắng [tăng tích tụ] trên răng', label: 'Cặn lắng [tăng tích tụ] trên răng' },
  { value: 'Biến màu mô cứng sau gãy răng', label: 'Biến màu mô cứng sau gãy răng' },
  { value: 'Bệnh xác định khác của mô cứng của răng', label: 'Bệnh xác định khác của mô cứng của răng' },
  { value: 'Bệnh mô cứng của răng, không đặc hiệu', label: 'Bệnh mô cứng của răng, không đặc hiệu' },
  { value: 'Bệnh tủy và mô quanh chân răng', label: 'Bệnh tủy và mô quanh chân răng' },
  { value: 'Viêm tủy', label: 'Viêm tủy' },
  { value: 'Hoại tử tủy', label: 'Hoại tử tủy' },
  { value: 'thoái hóa tủy', label: 'thoái hóa tủy' },
  { value: 'Tạo mô cứng bất thường trong tủy', label: 'Tạo mô cứng bất thường trong tủy' },
  { value: 'Viêm nha chu chân răng cấp có nguồn gốc tủy', label: 'Viêm nha chu chân răng cấp có nguồn gốc tủy' },
  { value: 'Viêm nha chu chân răng mãn', label: 'Viêm nha chu chân răng mãn' },
  { value: 'áp xe quanh chân răng có ổ', label: 'áp xe quanh chân răng có ổ' },
  { value: 'áp xe quanh chân răng không có ổ', label: 'áp xe quanh chân răng không có ổ' },
  { value: 'Nang có cuống', label: 'Nang có cuống' },
  {
    value: 'Bệnh tủy và mô quanh chân răng khác và không xác định',
    label: 'Bệnh tủy và mô quanh chân răng khác và không xác định',
  },
  { value: 'Viêm nướu và bệnh nha chu', label: 'Viêm nướu và bệnh nha chu' },
  { value: 'Viêm nướu cấp', label: 'Viêm nướu cấp' },
  { value: 'Viêm nướu mãn', label: 'Viêm nướu mãn' },
  { value: 'Viêm nha chu cấp', label: 'Viêm nha chu cấp' },
  { value: 'Viêm nha chu mãn', label: 'Viêm nha chu mãn' },
  { value: 'thoái hóa nha chu', label: 'thoái hóa nha chu' },
  { value: 'Bệnh nha chu', label: 'Bệnh nha chu' },
  { value: 'Bệnh nha chu, không đặc hiệu', label: 'Bệnh nha chu, không đặc hiệu' },
  {
    value: 'Rối loạn khác của nướu và sóng hàm vùng mất răng',
    label: 'Rối loạn khác của nướu và sóng hàm vùng mất răng',
  },
  { value: 'Tụt lợi răng', label: 'Tụt lợi răng' },
  { value: 'Lợi sưng', label: 'Lợi sưng' },
  {
    value: 'Chấn thương nướu răng và nang sống hàm liên quan đến chấn thương',
    label: 'Chấn thương nướu răng và nang sống hàm liên quan đến chấn thương',
  },
  { value: 'Rối loạn đặc hiệu khác của nướu và sống hàm', label: 'Rối loạn đặc hiệu khác của nướu và sống hàm' },
  { value: 'Rối loạn ở nướu và sóng hàm, không đặc hiệu', label: 'Rối loạn ở nướu và sóng hàm, không đặc hiệu' },
  { value: 'Bất thường hàm mặt [bao gồm khớp cắn lệch]', label: 'Bất thường hàm mặt [bao gồm khớp cắn lệch]' },
  { value: 'Bất thường chủ yếu của kích thước xương hàm', label: 'Bất thường chủ yếu của kích thước xương hàm' },
  { value: 'Bất thường có liên quan nền sọ xương hàm', label: 'Bất thường có liên quan nền sọ xương hàm' },
  { value: 'Bất thường liên quan đến cung răng', label: 'Bất thường liên quan đến cung răng' },
  { value: 'Vị trí răng bất thường', label: 'Vị trí răng bất thường' },
  { value: 'Khớp cắn lệch không xác định', label: 'Khớp cắn lệch không xác định' },
  { value: 'Bất thường chức năng hàm mặt', label: 'Bất thường chức năng hàm mặt' },
  { value: 'Rối loạn ở khớp thái dương', label: 'Rối loạn ở khớp thái dương' },
  { value: 'Các bất thường hàm mặt khác', label: 'Các bất thường hàm mặt khác' },
  { value: 'Bất thường hàm mặt không xác định', label: 'Bất thường hàm mặt không xác định' },
  { value: 'Bệnh khác của răng và cấu trúc nâng đỡ', label: 'Bệnh khác của răng và cấu trúc nâng đỡ' },
  { value: 'Mẻ răng do nguyên nhân hệ thống', label: 'Mẻ răng do nguyên nhân hệ thống' },
  {
    value: 'Mất răng do tai nạn, do nhổ răng hay bệnh nha chu khu trú',
    label: 'Mất răng do tai nạn, do nhổ răng hay bệnh nha chu khu trú',
  },
  { value: 'Teo ổ chân răng', label: 'Teo ổ chân răng' },
  { value: 'Chân răng còn sót', label: 'Chân răng còn sót' },
  {
    value: 'Bệnh đặc hiệu khác của răng và cấu trúc nâng đỡ',
    label: 'Bệnh đặc hiệu khác của răng và cấu trúc nâng đỡ',
  },
  {
    value: 'Bệnh của răng và cấu trúc nâng đỡ, không đặc hiệu',
    label: 'Bệnh của răng và cấu trúc nâng đỡ, không đặc hiệu',
  },
  { value: 'Nang vùng miệng, không phân loại nơi khác', label: 'Nang vùng miệng, không phân loại nơi khác' },
  { value: 'Nang răng phát triển', label: 'Nang răng phát triển' },
  { value: 'Nang (không do răng) của vùng miệng', label: 'Nang (không do răng) của vùng miệng' },
  { value: 'Nang khác của xương hàm', label: 'Nang khác của xương hàm' },
  {
    value: 'Nang khác ở vùng miệng, không phân loại nơi khác',
    label: 'Nang khác ở vùng miệng, không phân loại nơi khác',
  },
  { value: 'Nang vùng miệng không đặc hiệu', label: 'Nang vùng miệng không đặc hiệu' },
  { value: 'Bệnh khác của xương hàm', label: 'Bệnh khác của xương hàm' },
  { value: 'Rối loạn phát triển của xương hàm', label: 'Rối loạn phát triển của xương hàm' },
  { value: 'U hạt tế bào khổng lồ, trung tâm', label: 'U hạt tế bào khổng lồ, trung tâm' },
  { value: 'Tình trạng viêm của xương hàm', label: 'Tình trạng viêm của xương hàm' },
  { value: 'Viêm ổ răng xương hàm', label: 'Viêm ổ răng xương hàm' },
  { value: 'Bệnh xác định khác của xương hàm', label: 'Bệnh xác định khác của xương hàm' },
  { value: 'Bệnh xương hàm, không đặc hiệu', label: 'Bệnh xương hàm, không đặc hiệu' },
  { value: 'Bệnh tuyến nước bọt', label: 'Bệnh tuyến nước bọt' },
  { value: 'Teo tuyến nước bọt', label: 'Teo tuyến nước bọt' },
  { value: 'Phì đại tuyến nước bọt', label: 'Phì đại tuyến nước bọt' },
  { value: 'Viêm tuyến nước bọt', label: 'Viêm tuyến nước bọt' },
  { value: 'áp xe tuyến nước bọt', label: 'áp xe tuyến nước bọt' },
  { value: 'Lỗ dò tuyến nước bọt', label: 'Lỗ dò tuyến nước bọt' },
  { value: 'Bệnh sỏi tuyến nước bọt', label: 'Bệnh sỏi tuyến nước bọt' },
  { value: 'Nang nhầy của tuyến nước bọt', label: 'Nang nhầy của tuyến nước bọt' },
  { value: 'Rối loạn tiết nước bọt', label: 'Rối loạn tiết nước bọt' },
  { value: 'Bệnh khác của tuyến nước bọt', label: 'Bệnh khác của tuyến nước bọt' },
  { value: 'Bệnh tuyến nước bọt, không đặc hiệu', label: 'Bệnh tuyến nước bọt, không đặc hiệu' },
  { value: 'Viêm miệng và tổn thương liên quan', label: 'Viêm miệng và tổn thương liên quan' },
  { value: 'Loét miệng tái diễn', label: 'Loét miệng tái diễn' },
  { value: 'Dạng khác của viêm miệng', label: 'Dạng khác của viêm miệng' },
  { value: 'Viêm mô tế bào và áp xe của miệng', label: 'Viêm mô tế bào và áp xe của miệng' },
  { value: 'Viêm miệng (loét)', label: 'Viêm miệng (loét)' },
  { value: 'Bệnh khác của môi và niêm mạc miệng', label: 'Bệnh khác của môi và niêm mạc miệng' },
  { value: 'Bệnh của môi', label: 'Bệnh của môi' },
  { value: 'Đau má và môi', label: 'Đau má và môi' },
  {
    value: 'Mảng trắng và rối loạn khác của thượng bì miệng, bao gồm lưới',
    label: 'Mảng trắng và rối loạn khác của thượng bì miệng, bao gồm lưới',
  },
  { value: 'Bạch sản dạng tóc', label: 'Bạch sản dạng tóc' },
  {
    value: 'U hạt và tổn thương dạng u hạt của niêm mạc miệng',
    label: 'U hạt và tổn thương dạng u hạt của niêm mạc miệng',
  },
  { value: 'Xơ hóa dưới niêm mạc miệng', label: 'Xơ hóa dưới niêm mạc miệng' },
  { value: 'Tăng sản do kích thích của niêm mạc miệng', label: 'Tăng sản do kích thích của niêm mạc miệng' },
  {
    value: 'Tổn thương khác và không xác định của niêm mạc miệng',
    label: 'Tổn thương khác và không xác định của niêm mạc miệng',
  },
  { value: 'Bệnh của lưỡi', label: 'Bệnh của lưỡi' },
  { value: 'Viêm lưỡi', label: 'Viêm lưỡi' },
  { value: 'Lưỡi bản đồ', label: 'Lưỡi bản đồ' },
  { value: 'Viêm lưỡi dạng thoi', label: 'Viêm lưỡi dạng thoi' },
  { value: 'Phì đại gai lưỡi', label: 'Phì đại gai lưỡi' },
  { value: 'Teo gai lưỡi', label: 'Teo gai lưỡi' },
  { value: 'Lưỡi bị gấp nếp', label: 'Lưỡi bị gấp nếp' },
  { value: 'Đau lưỡi', label: 'Đau lưỡi' },
  { value: 'Bệnh khác của lưỡi', label: 'Bệnh khác của lưỡi' },
  { value: 'Bệnh lưỡi, không đặc hiệu', label: 'Bệnh lưỡi, không đặc hiệu' },
  { value: 'Khám răng', label: 'Khám răng' },
];

export const MEDICAL_ILLNESS_OPTION = [
  { value: 'Bệnh tim mạch', label: 'Bệnh tim mạch' },
  { value: 'Ức chế miễn dịch', label: 'Ức chế miễn dịch' },
  { value: 'Huyết áp cao', label: 'Huyết áp cao' },
  { value: 'Ung thư', label: 'Ung thư' },
  { value: 'Tiểu đường', label: 'Tiểu đường' },
  { value: 'Rối loạn huyết học', label: 'Rối loạn huyết học' },
  { value: 'Dị ứng', label: 'Dị ứng' },
  { value: 'Bệnh ác tính', label: 'Bệnh ác tính' },
];

export const THERAPY_REFERENCE_TYPE = {
  DENTAL: 'DENTAL',
  ENCOUNTER: 'ENCOUNTER',
} as const;

export const MEDICAL_SUPPLIES_USAGE_TYPE = {
  THERAPY_SESSION: 'THERAPY_SESSION',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL_EXAM: 'DENTAL_EXAM',
} as const;

export const DIAGNOSTIC_IMAGE_TYPE = {
  PATIENT_DENTAL: { value: 'PATIENT_DENTAL' },
  ENCOUNTER: { value: 'ENCOUNTER' },
  THERAPY_SESSION: { value: 'THERAPY_SESSION' },
  DENTAL_DIAGNOSIS: { value: 'DENTAL_DIAGNOSIS' },
} as const;

export const THERAPY_DEFINITION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export const SERVICE_TYPE = {
  DENTAL: { value: 'DENTAL', label: 'report.summary.serviceDental' },
  THERAPY: { value: 'THERAPY', label: 'report.summary.serviceTherapy' },
} as const;

export const ROUTE_TYPE = {
  THERAPY: { value: 'THERAPY' },
  DENTAL_EXAM: { value: 'DENTAL_EXAM' },
  ENCOUNTER: { value: 'ENCOUNTER' },
} as const;

export const APPOINTMENT_STATUS = {
  BOOKED: {
    value: 'BOOKED',
    label: 'appointmentBook.book',
  },
  ARRIVED: {
    value: 'ARRIVED',
    label: 'appointmentBook.arrived',
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'appointmentBook.cancel',
  },
} as const;

export const ROLES = {
  MEDRING_ADMIN: { value: 'MEDRING_ADMIN', description: 'Quản trị viên nhà cung cấp phần mềm MEDRING', label: '' },
  MEDRING_MANAGER: { value: 'MEDRING_MANAGER', description: 'Quản lý nhà cung cấp phần mềm MEDRING', label: '' },
  MEDRING_USER: { value: 'MEDRING_USER', description: 'Người dùng nhà cung cấp phần mềm MEDRING', label: '' },
  ADMIN: {
    value: 'ADMIN',
    description: 'Quản trị viên hệ thống phòng khám - Dùng ở bản Standard|Basic',
    label: 'account.admin',
  },
  DOCTOR: { value: 'DOCTOR', description: 'Bác sĩ khám - Dùng ở bản Standard|Basic', label: 'account.examDoctor' },
  GENERAL_DOCTOR: {
    value: 'GENERAL_DOCTOR',
    description: 'Bác sĩ tổng quát - Dùng ở bản Basic',
    label: 'account.general',
  },
  NURSE: {
    value: 'NURSE',
    description: 'Điểu dưỡng - Dùng ở bản Standard',
    label: 'account.nurse',
  },
  PHARMACIST: {
    value: 'PHARMACIST',
    description: 'Dược sĩ/Nhân viên bán - Dùng ở bản Standard|Basic',
    label: 'account.pharmacist',
  },
  CASHIER: { value: 'CASHIER', description: 'Thu ngân - Dùng ở bản Standard', label: 'account.cashier' },
  COORDINATOR: {
    value: 'COORDINATOR',
    description: 'Điều phối viên (Thu ngân/Lễ tân) - Dùng ở bản Basic',
    label: 'account.frontOffice',
  },
  RADIOGRAPHER: {
    value: 'RADIOGRAPHER',
    description: 'Kĩ thuật viên chuẩn đoán hình ảnh - Dùng ở bản Standard',
    label: 'account.imageTechnician',
  },
  LABORATORY_TECHNOLOGIST: {
    value: 'LABORATORY_TECHNOLOGIST',
    description: 'Kỹ thuật viên xét nghiệm - Dùng ở bản Standard',
    label: 'account.laboratory',
  },
  RADIOLOGIST: {
    value: 'RADIOLOGIST',
    description: 'Bác sĩ chuẩn đoán hình ảnh - Dùng ở bản Standard',
    label: 'account.imageSpecialist',
  },
  CLINICAL_PATHOLOGIST: {
    value: 'CLINICAL_PATHOLOGIST',
    description: 'Bác sĩ xét nghiệm - Dùng ở bản Standard',
    label: 'account.labDoctor',
  },
  RECEPTIONIST: { value: 'RECEPTIONIST', description: 'Lễ tân - Dùng ở bản Standard', label: 'account.reception' },
} as const;

export const ATTEND_PERSON_TYPE = {
  THERAPY: 'THERAPY',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL: 'DENTAL',
  INDICATION: 'INDICATION',
  THERAPY_SESSION: 'THERAPY_SESSION',
};

export const COLLABORATOR_USER_TYPE = {
  PATIENT: 'PATIENT',
  EMPLOYEE: 'EMPLOYEE',
} as const;

export const DENTAL_OBJECT_TYPE = {
  TOOTH: 'TOOTH', // răng
  GUMS: 'GUMS', // nướu
  JAW: 'JAW', // hàm
  MOUTH: 'MOUTH', // Miệng
  TONGUE: 'TONGUE', // Lưỡi
  PERIODONTAL: 'PERIODONTAL', //nha chu
  GENERAL: 'GENERAL', // Chung
};

export const DENTAL_OBJECT_DETAIL = {
  TOOTH_OUTSIDE: 'TOOTH_OUTSIDE',
  TOOTH_INSIDE: 'TOOTH_INSIDE',
  TOOTH_OCCLUSAL: 'TOOTH_OCCLUSAL',
  TOOTH_ALL: 'TOOTH_ALL',
  JAW_UPPER: 'JAW_UPPER',
  JAW_BOTTOM: 'JAW_BOTTOM',
  JAW_ALL: 'JAW_ALL',
  TONGUE_UPPER: 'TONGUE_UPPER',
  TONGUE_BOTTOM: 'TONGUE_BOTTOM',
};

export const DENTAL_DIGAGNOSIS_BASIC_TYPE = {
  DENTAL: 'DENTAL',
  MEDICAL_HISTORY: 'MEDICAL_HISTORY',
};

export const DENTAL_TYPE = {
  CHILD: {
    value: 'CHILD',
    label: 'dental.dentalDetail.odontogram.label.children',
  },
  ADULT: {
    value: 'ADULT',
    label: 'dental.dentalDetail.odontogram.label.adult',
  },
};

export const EMPLOYMENT_STATUS = {
  OTHER: 'OTHER',
  NOT_WORKING: 'NOT_WORKING',
  PNS: 'PNS',
  TNI_POLRI: 'TNI_POLRI',
  BUMN: 'BUMN',
  PRIVATE_EMPLOYEE: 'PRIVATE_EMPLOYEE',
};

export const MARITAL_STATUS = {
  UNMARRIED: 'UNMARRIED',
  MARRIED: 'MARRIED',
  DIVORCED: 'DIVORCED',
  WIDOWED: 'WIDOWED',
};

export const BASE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PATIENT_TYPE = {
  NEW: {
    value: 'NEW',
  },
  RE_EXAMINATION: {
    value: 'RE_EXAMINATION',
  },
};

export const MAX_FILE_SIZE = 5000000; // bytes ~ 50 MB
