import findDeep from 'deepdash/es/findDeep';
import moment, { Moment } from 'moment';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  BILLING_STATUS,
  PATIENT_ADDRESS_TYPE,
  PATIENT_DE_URL,
  PATIENT_PAY_URL,
  some,
  SS_PAY_URL,
} from '../../common/constants';
import { CATEGORY_INSURANCE, ENTER_REASON_VALUE } from '../constants';
import { determineCategory } from '../utils';
import { AppState } from '../../../redux/reducer';
import { formatOption } from '../../common/utils';
import { GENDER, PATIENT_CONTACT_TYPE } from 'modules/common/apiConstants';
import { Patient } from 'modules/schema';

export function within3DaysNotInFuture(entryDate: Moment) {
  if (moment().subtract(3, 'days').isBefore(entryDate)) {
    if (!entryDate.isAfter(moment().endOf('day'))) {
      return true;
    }
  }
  return false;
}

export const mapPatientsByTel = (data: some) => {
  const returnValue = {
    patientsByTel: [] as some[],
    accountMapper: new Map<string, some>(),
    coverageMapper: new Map<string, some>(),
  };

  if (data && data.entry) {
    const entries: some[] = data.entry;

    const { accountMapper, coverageMapper } = returnValue;

    let appointment: some | null = null;

    entries.forEach((entry) => {
      if (entry.resource.resourceType === 'Coverage') {
        if (entry.resource.status === 'active') {
          coverageMapper.set(entry.resource.beneficiary.reference, entry.resource);
        }
      } else if (entry.resource.resourceType === 'Account') {
        accountMapper.set(entry.resource.subject?.[0]?.reference, entry.resource);
      } else if (entry.resource.resourceType === 'Appointment') {
        if (appointment == null) {
          const entryDate = moment(entry.resource.start, BE_DATE_FORMAT);
          if (within3DaysNotInFuture(entryDate)) {
            appointment = entry.resource;
          }
        } else {
          const appointmentDate = moment(appointment.start, BE_DATE_FORMAT);
          const entryDate = moment(entry.resource.start, BE_DATE_FORMAT);
          if (appointmentDate.isBefore(entryDate)) {
            if (within3DaysNotInFuture(entryDate)) {
              appointment = entry.resource;
            }
          }
        }
      }
    });

    const patientsByTel = entries
      .filter((entry) => entry.resource.resourceType === 'Patient')
      .map((entry) => {
        const accountEntry = accountMapper.get(`Patient/${entry.resource.id}`);
        const coverageEntry = coverageMapper.get(`Patient/${entry.resource.id}`);

        const categoryInsurance = determineCategory(coverageEntry);
        const coverage = {
          insuranceNumber: '',
          insuranceFromDate: categoryInsurance === CATEGORY_INSURANCE.noCard ? moment().format(BE_DATE_FORMAT) : '',
          insuranceToDate: '',
          registeredHospital: '',
          registeredHospitalCode: '',
        };
        if (coverageEntry) {
          coverage.insuranceNumber = coverageEntry.identifier[0]?.value || '';
        }

        const districtRaw = entry.resource.address?.[0]?.district || '';
        const cityRaw = entry.resource.address?.[0]?.city || '';
        const contact = entry.resource.contact?.[0];

        const ethnicId = entry.resource.extension?.find(
          (one) => one?.url === 'http://hl7.org/fhir/StructureDefinition/patient-ethnic',
        )?.valueCodeableConcept.coding[0]?.code;

        const nationId = entry.resource.extension?.find(
          (one) => one?.url === 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
        )?.valueCodeableConcept.coding[0]?.code;

        const returnValue = {
          patientResourceId: entry.resource.id,
          patientAccountId: accountEntry?.id || '',
          coverageId: coverageEntry?.id || '',
          appointmentId: appointment?.id || undefined,
          appointmentReason: appointment?.reasonCode?.[0]?.text || '',
          name: entry.resource.name?.[0]?.text,
          birthday: entry.resource.birthDate,
          gender: entry.resource.gender,
          telephone: entry.resource.telecom?.find((one) => one?.system === 'phone')?.value || '',
          email: entry.resource.telecom?.find((one) => one?.system === 'email')?.value || '',
          identification: entry.resource.identifier ? entry.resource.identifier[0]?.value || '' : '',
          patientCode: entry.resource.id,
          ethnic: ethnicId
            ? {
                value: ethnicId,
                label: entry.resource.extension?.find(
                  (one) => one?.url === 'http://hl7.org/fhir/StructureDefinition/patient-ethnic',
                )?.valueCodeableConcept.coding[0]?.display,
              }
            : null,
          nation: nationId
            ? {
                value: nationId,
                label: entry.resource.extension?.find(
                  (one) => one?.url === 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
                )?.valueCodeableConcept.coding[0]?.display,
              }
            : null,
          address: entry.resource.address?.[0]?.line?.[0],
          jobAddress: entry.resource.address?.[1]?.text,
          job: entry.resource.extension?.find(
            (one) => one?.url === 'http://hl7.org/fhir/StructureDefinition/patient-job',
          )?.valueString,
          district: districtRaw ? { value: districtRaw.split('|')[1], label: districtRaw.split('|')[0] } : null,
          province: cityRaw ? { value: cityRaw.split('|')[1], label: cityRaw.split('|')[0] } : null,
          contactName: contact?.name?.text || null,
          contactAddress: contact?.address?.text || null,
          contactTelephone: contact?.telecom?.find((one) => one?.system === 'phone')?.value || null,
          categoryInsurance,

          ...coverage,
          requireMoreInfo: !!entry.resource.gender,
        };
        return returnValue;
      });

    returnValue.patientsByTel = patientsByTel;
  }
  return returnValue.patientsByTel;
};

export function createPatientRequestBody(formData: some) {
  // const currentYear = moment().year();
  // const dob = moment(formData?.age ? new Date(currentYear - formData?.age, 0, 1) : formData?.dob);
  const dobSubtract = moment()
    .subtract(parseInt(formData?.age), 'years')
    .subtract(parseInt(formData?.month), 'months')
    .subtract(0, 'days');
  const dob = moment(
    formData?.age || formData?.month ? new Date(`${dobSubtract.year()}/${dobSubtract.month() + 1}/01`) : formData?.dob,
  );
  return {
    dob: dob.format(BE_DATE_FORMAT),
    email: formData.email,
    ethnic: formData?.ethnic?.label,
    gender: formData?.gender?.toUpperCase(),
    identifierCode: formData?.identifierCode,
    job: formData?.job,
    name: formData?.name,
    nationality: formData?.nationality?.label,
    mobilePhone: formData?.mobilePhone,
    patientAddressList: [
      {
        address: formData?.jobAddress,
        type: PATIENT_ADDRESS_TYPE.work,
      },
      {
        address: formData?.address,
        district: formatOption(formData?.district),
        province: formatOption(formData?.province),
        type: PATIENT_ADDRESS_TYPE.home,
      },
    ],
    patientContactInfoList: [
      {
        type: PATIENT_CONTACT_TYPE.PERSON_IN_CONTACT,
        address: formData?.contactAddress,
        name: formData?.contactName,
        mobilePhone: formData?.contactTelephone,
      },
    ],
  };
}

export function createAccountRequestBody(patientId: string) {
  return {
    resourceType: 'Account',
    subject: [
      {
        reference: `Patient/${patientId}`,
      },
    ],
  };
}

export function createEncounterRequestBody(
  patientId: string,
  accountId: string,
  roomId: string,
  reason: string,
  episodeOfCareId: string,
  enterReason: string,
  encounterNumber: number,
  accidentCode: some,
  examDoctorId: string,
  paymentCategoryId: string,
  orgId: string,
  sequenceNumber: string,
  appointmentId?: string,
) {
  const appointment = appointmentId ? { appointment: [{ reference: `Appointment/${appointmentId}` }] } : {};

  return {
    resourceType: 'Encounter',
    subject: {
      reference: `Patient/${patientId}`,
    },
    account: [
      {
        reference: `Account/${accountId}`,
      },
    ],
    ...appointment,
    location: [
      {
        location: {
          reference: `Location/${roomId}`,
          status: 'planned',
        },
      },
    ],
    period: {
      start: moment().format(),
    },
    class: {
      system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
      code: 'AMB',
      display: 'ambulatory',
    },
    reasonCode: accidentCode
      ? [
          {
            coding: [
              {
                system: 'http://hl7.org/fhir/injury-accident-code',
                code: accidentCode.value,
                display: accidentCode.label,
              },
            ],
          },
          {
            text: reason || '',
          },
        ]
      : [
          {
            text: reason || '',
          },
        ],
    status: 'arrived',
    episodeOfCare: [
      {
        reference: `EpisodeOfCare/${episodeOfCareId}`,
      },
    ],
    serviceProvider: orgId
      ? {
          reference: `Organization/${orgId}`,
        }
      : {},
    participant: [
      {
        type: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: examDoctorId
          ? {
              reference: `PractitionerRole/${examDoctorId}`,
            }
          : {},
      },
    ],
    extension: [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/social-security-encounter-number',
        valueUnsignedInt: encounterNumber || 0,
      },
    ],
    type: [
      {
        coding: enterReason
          ? [
              enterReason === ENTER_REASON_VALUE.correctRoute
                ? {
                    system: 'http://terminology.hl7.org/CodeSystem/benefit-network',
                    code: 'in',
                    display: 'In Network',
                  }
                : enterReason === ENTER_REASON_VALUE.incorrectRoute
                ? {
                    system: 'http://terminology.hl7.org/CodeSystem/benefit-network',
                    code: 'out',
                    display: 'Out of Network',
                  }
                : enterReason === ENTER_REASON_VALUE.emergency
                ? {
                    system: 'http://snomed.info/sct',
                    code: '25876001',
                    display: 'Emergency',
                  }
                : enterReason === ENTER_REASON_VALUE.referral
                ? {
                    system: 'http://snomed.info/sct',
                    code: '3457005',
                    display: 'Referral',
                  }
                : {
                    system: 'http://terminology.hl7.org/CodeSystem/coverage-selfpay',
                    code: 'pay',
                    display: 'Pay',
                  },
            ]
          : [],
      },
      {
        coding: [
          paymentCategoryId === '0'
            ? {
                system: 'http://hl7.org/fhir/payment-category',
                code: 0,
                display: 'BHYT',
              }
            : paymentCategoryId === '1'
            ? {
                system: 'http://hl7.org/fhir/payment-category',
                code: 1,
                display: 'Thu phí',
              }
            : paymentCategoryId === '2'
            ? {
                system: 'http://hl7.org/fhir/payment-category',
                code: 2,
                display: 'Miễn',
              }
            : {
                system: 'http://hl7.org/fhir/payment-category',
                code: 3,
                display: 'Khác',
              },
        ],
      },
    ],
    identifier: [
      {
        type: {
          text: 'sequence-number',
        },
        value: sequenceNumber,
      },
    ],
  };
}

export function createChargeItemRequestBody(args: {
  chargeItemDefId: string;
  accountId: string;
  encounterId: string;
  healthCareServiceId: string;
  healthcareServiceCategoryId: string;
  price: number;
  insurancePay: number;
  originalPrice: number;
  reducedRate: number;
  patientId: string;
  notBillable: boolean;
  appState: AppState;
}) {
  const {
    chargeItemDefId,
    accountId,
    encounterId,
    healthCareServiceId,
    healthcareServiceCategoryId,
    price,
    insurancePay,
    originalPrice,
    reducedRate,
    patientId,
    notBillable,
    appState,
  } = args;
  return {
    resourceType: 'ChargeItem',
    status: notBillable ? BILLING_STATUS.notBillable : BILLING_STATUS.billable,
    quantity: {
      value: 1,
    },
    definitionCanonical: [`ChargeItemDefinition/${chargeItemDefId}`],
    account: [
      {
        reference: `Account/${accountId}`,
      },
    ],
    context: {
      reference: `Encounter/${encounterId}`,
    },
    enteredDate: moment(),
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '224891009',
          display: 'Healthcare services',
        },
      ],
    },
    factorOverride: reducedRate,
    priceOverride: {
      value: originalPrice,
      currency: appState.common.groupCurrency,
    },
    subject: { reference: `Patient/${patientId}` },
    supportingInformation: [
      {
        reference: `HealthcareService/${healthCareServiceId}`,
      },
      {
        reference: healthcareServiceCategoryId ? `List/${healthcareServiceCategoryId}` : '',
      },
    ],
    extension: [
      {
        url: SS_PAY_URL,
        valueMoney: {
          value: insurancePay,
          currency: appState.common.groupCurrency,
        },
      },
      {
        url: PATIENT_PAY_URL,
        valueMoney: {
          value: price - insurancePay,
          currency: appState.common.groupCurrency,
        },
      },
      {
        url: PATIENT_DE_URL,
        valueMoney: {
          value: 0,
          currency: appState.common.groupCurrency,
        },
      },
    ],
  };
}

export function getCategory2FromEncounter(encounter: some) {
  if (
    !!findDeep(
      encounter?.type,
      (val) => val?.system === 'http://terminology.hl7.org/CodeSystem/benefit-network' && val?.code === 'in',
      { leavesOnly: false },
    )?.value
  ) {
    return ENTER_REASON_VALUE.correctRoute;
  } else if (
    !!findDeep(
      encounter?.type,
      (val) => val?.system === 'http://terminology.hl7.org/CodeSystem/benefit-network' && val?.code === 'out',
      { leavesOnly: false },
    )?.value
  ) {
    return ENTER_REASON_VALUE.incorrectRoute;
  } else if (
    !!findDeep(encounter?.type, (val) => val?.system === 'http://snomed.info/sct' && val?.code === '25876001', {
      leavesOnly: false,
    })?.value
  ) {
    return ENTER_REASON_VALUE.emergency;
  } else if (
    !!findDeep(encounter?.type, (val) => val?.system === 'http://snomed.info/sct' && val?.code === '3457005', {
      leavesOnly: false,
    })?.value
  ) {
    return ENTER_REASON_VALUE.referral;
  } else if (
    !!findDeep(
      encounter?.type,
      (val) => val?.system === 'http://terminology.hl7.org/CodeSystem/coverage-selfpay' && val?.code === 'pay',
      { leavesOnly: false },
    )?.value
  ) {
    return ENTER_REASON_VALUE.uninsured;
  } else {
    return ENTER_REASON_VALUE.uninsured;
  }
}

export function createRegisterInsuranceInfoReqBody(insuranceId: string, patientId: string, temporary: boolean) {
  return {
    resourceType: 'Coverage',
    status: 'active',
    type: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'PUBLICPOL',
          display: 'public healthcare',
        },
      ],
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
              code: 'NIIP',
              display: 'National Insurance Payor Identifier (Payor)',
            },
          ],
        },
        value: insuranceId,
      },
    ],
    class: temporary
      ? [{ type: { coding: [{ system: 'http://snomed.info/sct', code: '276208004', display: 'Temporary type' }] } }]
      : undefined,
    beneficiary: {
      reference: `Patient/${patientId}`,
    },
  };
}

export function editAppointmentRequestBody(date: string, reason: string, isEdit: boolean) {
  return [
    { op: 'replace', path: '/start', value: date },
    { op: !reason ? 'remove' : isEdit ? 'replace' : 'add', path: '/reasonCode', value: [{ text: reason }] },
  ]?.filter(Boolean);
}

export function createAppointmentRequestBody(patientId: string, locationId: string, date: string, reason: string) {
  return {
    resourceType: 'Appointment',
    participant: [
      {
        actor: {
          reference: `Patient/${patientId}`,
        },
        required: 'required',
        status: 'accepted',
      },
      {
        actor: {
          reference: `Location/${locationId}`,
        },
        required: 'required',
        status: 'accepted',
      },
    ],
    start: date,
    reasonCode: [
      {
        text: `${reason}`,
      },
    ],
    status: 'booked',
  };
}

export function createCoverageEligibilityResponseRequestBody(
  converageId: string,
  start: string,
  end: string,
  registeredHospital: string,
  registeredHospitalCode: string,
  encounterId: string,
  patientId: string,
  copayPercent: number,
) {
  return {
    resourceType: 'CoverageEligibilityResponse',
    patient: {
      reference: `Patient/${patientId}`,
    },
    insurance: [
      {
        coverage: {
          reference: `Coverage/${converageId}`,
        },
        benefitPeriod: {
          start,
          end,
        },
        item: [
          {
            network: {
              text: `${registeredHospital}|${registeredHospitalCode}`,
            },
            benefit: [
              {
                type: {
                  coding: [
                    {
                      system: 'http://terminology.hl7.org/CodeSystem/benefit-type',
                      code: 'copay-percent',
                      display: 'Copayment Percent per service',
                    },
                  ],
                },
                allowedUnsignedInt: copayPercent,
              },
            ],
          },
        ],
      },
    ],
    extension: [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/workflow-supportingInfo',
        valueReference: {
          reference: `Encounter/${encounterId}`,
        },
      },
    ],
  };
}

export const DISPLAY_DURATION = 4;

export function getMonthYear(start: Moment, end: Moment, locale: string) {
  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      return start.locale(locale).format('MMMM yyyy');
    }
    return `${start.locale(locale).format('MMMM')} - ${end.locale(locale).format('MMMM')} ${start.format('yyyy')}`;
  }
  return `${start.locale(locale).format('MMMM yyyy')} - ${end.locale(locale).format('MMMM yyyy')}`;
}

export const mapSearchDataAppointment = (entries: some[]) => {
  const patientMapper = new Map<string, some>();
  const returnValue: some[] = [];
  if (entries) {
    entries.forEach((entry) => {
      if (entry.resource.resourceType === 'Patient') {
        patientMapper.set(`Patient/${entry.resource.id}`, entry.resource);
      }
    });

    const appointmentEncounters = entries
      .filter((entry) => entry.resource.resourceType === 'Appointment')
      .map((entry) => {
        const patientResource = patientMapper.get(entry.resource.participant[0]?.actor.reference) as some;
        const returnValue = {
          receptionNumber: entry.resource.rn,
          patientResourceId: patientResource.id,
          name: patientResource.name?.[0]?.text,
          birthDate: patientResource.birthDate,
          telephone: patientResource.telecom?.find((v) => v.system === 'phone')?.value || '',
          reasons: entry.resource.reasonCode,
          start: entry.resource.start,
          status: entry.resource.status,
          id: entry.resource.id,
        };
        return returnValue;
      });
    returnValue.push(...appointmentEncounters);
  }
  return returnValue;
};

export function getCopayRate(insuranceId: string, data: some[]) {
  for (let one of data) {
    if (one.prefix === insuranceId.substr(0, 3)) {
      return one.copayPercent / 100;
    }
  }
  return 1;
}

export function formatPatientFormData(patient: Patient, appState: AppState) {
  const birthday = patient?.dob && moment(patient?.dob, BE_DATE_TIME_FORMAT);
  const contactInfo = patient?.patientContactInfoList?.[0];
  const workAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.work);
  const homeAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.home);

  const ethnic = appState?.common?.ethnicList?.find((ele) => ele.label === patient?.ethnic);
  const country = appState?.common?.countryList?.find((ele) => ele.label === patient?.nationality);
  const province = appState?.common?.provinceData?.find(
    (ele) => formatOption(ele as { value: string; label: string }) === homeAddress?.province,
  );
  const district = province?.districts?.find((ele) => formatOption(ele) === homeAddress?.district);
  return {
    patientId: patient?.id,
    name: patient?.name,
    mobilePhone: patient?.mobilePhone,
    code: patient?.code?.split('-')?.[1],
    dob: birthday,
    gender: GENDER[patient?.gender!]?.value,
    ethnic: ethnic,
    email: patient?.email,
    jobAddress: workAddress?.address,
    address: homeAddress?.address,
    job: patient?.job,
    identifierCode: patient?.identifierCode,
    insuranceName: patient?.insuranceName,
    insuranceNumber: patient?.insuranceNumber,
    nationality: country,
    province: province,
    district: district,
    contactName: contactInfo?.name,
    contactTelephone: contactInfo?.mobilePhone,
    contactAddress: contactInfo?.address,
  };
}

export function formatDentalExamFormData(dentalExamInfo: some) {
  return {
    ...dentalExamInfo,
    dentalExamId: dentalExamInfo.id,
    healthcareServiceItem: {
      id: dentalExamInfo.serviceId,
      name: dentalExamInfo.serviceName,
    },
    reason: dentalExamInfo.registerReason,
    doctor: dentalExamInfo.picUserId,
    physicsRoom: parseInt(dentalExamInfo.physicsRoomId),
  };
}
