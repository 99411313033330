import { Box, Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PatientDetailSession from 'modules/common/component/Patient/PatientDetailSession';
import { PatientSessionProps } from 'modules/common/component/Patient/type';
import { Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import MediCard from 'modules/common/component/MediCard';
import { useCallback, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Avatar } from 'svg';
import { GENDER } from 'modules/common/apiConstants';
import moment from 'moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import PatientDialog from 'modules/admin/component/patientManagement/PatientDialog';
import { formatPatientFormData } from 'modules/common/utils';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { API_SERVER } from 'modules/common/api';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { BoxCustom, TypographyCustom } from './styled';
import { formatPatientDataToForm } from 'modules/reception/component/encounter/ReceiveWizardDialog/utils';
import { mutate } from 'swr';
import { Patient } from 'modules/schema';

interface Props {
  patient: Patient;
  onOpen: () => void;
}

const EditPatientInfo = ({ patient, onOpen }: Props) => {
  const onUpdatePatient = useCallback(
    (patient) => {
      if (patient && patient.id) {
        onOpen();
      }
    },
    [onOpen],
  );

  return (
    <IconButton
      sx={{
        padding: 0,
        '&:hover': {
          backgroundColor: 'unset',
        },
        color: '#78909C',
      }}
      onClick={() => {
        onUpdatePatient(patient);
      }}
    >
      <Edit fontSize="small" />
    </IconButton>
  );
};

const PatientInfo = ({ patient }: PatientSessionProps) => {
  const { appState } = useGeneralHook();
  const [open, onOpen, onClose] = useDialog();
  const [collapseMoreInfo, setCollapseMoreInfo] = useState(false);
  const [collapseContactInfo, setCollapseContactInfo] = useState(false);
  const [collapseNote, setCollapseNote] = useState(false);

  const onUpdatePatientInfoMutate = useUpdateMutate({
    onSuccess: () => {
      patient?.id && mutate(API_SERVER.patient.getPatient(patient?.id).url);
      onClose();
    },
  });

  return (
    <>
      <PatientDetailSession
        titleKey="patient.patientDetail.session.title.basicInfo"
        rightAction={<EditPatientInfo onOpen={onOpen} patient={patient} />}
      >
        <Box>
          <Typography align="center" marginBottom={'15px'}>
            <Avatar width={'100%'} />
          </Typography>
          <Typography variant="h6" align="center" marginBottom={'30px'} color={'#263238'}>
            {patient?.name}
          </Typography>
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id="basicInfo" />
            </Typography>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="patientCode" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">{patient?.formatCode}</TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="dental.patient.label.fullName" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">{patient?.name}</TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="telephone" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">{patient?.mobilePhone}</TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="birthdayShort" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">
                  {moment(patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                </TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="age_" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">
                  {patient?.age} ({patient?.month})
                </TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="gender" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <TypographyCustom variant="subtitle2">
                  {patient?.gender && <FormattedMessage id={GENDER[patient?.gender!]?.label} />}
                </TypographyCustom>
              </Grid>
            </Grid>
            <Grid container borderBottom={'1px solid #455a6424'}>
              <Grid item xs={6} color={'#455A64'}>
                <TypographyCustom variant="subtitle2">
                  <FormattedMessage id="address" />
                </TypographyCustom>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={patient?.homeAddress?.address || ''}>
                  <Typography
                    component={'span'}
                    variant="subtitle2"
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    padding={'5px 0'}
                    lineHeight={'24px'}
                    display={'block'}
                  >
                    {patient?.homeAddress?.address}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <MediCard
            sx={{
              border: 'none!important',
              '.MuiCardHeader-root': { background: 'none!important', padding: '20px 0 0!important' },
            }}
            title={
              <BoxCustom
                onClick={() => {
                  setCollapseMoreInfo(!collapseMoreInfo);
                }}
              >
                <Typography variant="subtitle1" color={'#263238'}>
                  <FormattedMessage id="moreInfo" />
                </Typography>
                <ExpandLessIcon
                  sx={{
                    transition: '0.2s all',
                    transform: collapseMoreInfo ? 'rotate(0deg)' : 'rotate(180deg)',
                    marginRight: '2px',
                    color: '#455A64',
                  }}
                />
              </BoxCustom>
            }
          >
            <Collapse in={collapseMoreInfo} unmountOnExit>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="email" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.email}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="patient.management.dialog.formLabel.cccd" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.identifierCode}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="insuranceName" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.insuranceName}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="insuranceNumber" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                    {patient?.insuranceNumber}
                  </TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="patient.management.dialog.formLabel.job" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.job}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="jobAddress" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={patient?.workAddress?.address || ''}>
                    <Typography
                      component={'span'}
                      variant="subtitle2"
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      padding={'5px 0'}
                      lineHeight={'24px'}
                    >
                      {patient?.workAddress?.address}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="ethnic" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.ethnic}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="nation" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">{patient?.nationality}</TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="province" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">
                    {patient?.homeAddress?.province!?.substring(0, patient?.homeAddress?.province!.indexOf('|'))}
                  </TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="patient.management.dialog.formLabel.district" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">
                    {patient?.homeAddress?.district!?.substring(0, patient?.homeAddress?.district!.indexOf('|'))}
                  </TypographyCustom>
                </Grid>
              </Grid>
            </Collapse>
          </MediCard>
          <MediCard
            sx={{
              border: 'none!important',
              '.MuiCardHeader-root': { background: 'none!important', padding: '20px 0 0!important' },
            }}
            title={
              <BoxCustom
                onClick={() => {
                  setCollapseContactInfo(!collapseContactInfo);
                }}
              >
                <Typography variant="subtitle1" color={'#263238'}>
                  <FormattedMessage id="contactInfoPerson" />
                </Typography>
                <ExpandLessIcon
                  sx={{
                    transition: '0.2s all',
                    transform: collapseContactInfo ? 'rotate(0deg)' : 'rotate(180deg)',
                    marginRight: '2px',
                    color: '#455A64',
                  }}
                />
              </BoxCustom>
            }
          >
            <Collapse in={collapseContactInfo} unmountOnExit>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="contactName" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">
                    {patient?.patientContactInfoList && patient?.patientContactInfoList[0]?.name}
                  </TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="telephone" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <TypographyCustom variant="subtitle2">
                    {patient?.patientContactInfoList![0]?.mobilePhone}
                  </TypographyCustom>
                </Grid>
              </Grid>
              <Grid container borderBottom={'1px solid #455a6424'}>
                <Grid item xs={6} color={'#455A64'}>
                  <TypographyCustom variant="subtitle2">
                    <FormattedMessage id="address" />
                  </TypographyCustom>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={patient?.patientContactInfoList![0]?.address || ''}>
                    <Typography
                      component={'span'}
                      variant="subtitle2"
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      padding={'5px 0'}
                      lineHeight={'24px'}
                    >
                      {patient?.patientContactInfoList![0]?.address}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Collapse>
          </MediCard>
          <MediCard
            sx={{
              border: 'none!important',
              '.MuiCardHeader-root': { background: 'none!important', padding: '20px 0 0!important' },
            }}
            title={
              <BoxCustom
                onClick={() => {
                  setCollapseNote(!collapseNote);
                }}
              >
                <Typography variant="subtitle1" color={'#263238'}>
                  <FormattedMessage id="note" />
                </Typography>
                <ExpandLessIcon
                  sx={{
                    transition: '0.2s all',
                    transform: collapseNote ? 'rotate(0deg)' : 'rotate(180deg)',
                    marginRight: '2px',
                    color: '#455A64',
                  }}
                />
              </BoxCustom>
            }
          >
            <Collapse in={collapseNote} unmountOnExit>
              <TypographyCustom variant="subtitle2">{patient?.note}</TypographyCustom>
            </Collapse>
          </MediCard>
        </Box>
      </PatientDetailSession>
      <PatientDialog
        formData={formatPatientDataToForm(patient, appState)}
        open={open}
        onClose={onClose}
        onSubmit={(formData) => {
          const data = formatPatientFormData(formData);
          onUpdatePatientInfoMutate({
            url: API_SERVER.patient.edit(patient?.id),
            method: 'put',
            data,
          });
        }}
        mode={'edit'}
      />
    </>
  );
};

export default PatientInfo;
