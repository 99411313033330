import { Collapse, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import { FieldsType, ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import { EMAIL_REGEX } from '../../../../common/regex';
import { GENDER_OPTIONS } from '../../../../reception/constants';
import moment from 'moment/moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../../common/constants';
import { VN_CODE } from '../../../../common/redux/commonReducer';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CodeSystem } from 'modules/schema';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, onCancel, appState, mode } = formProps || {};
  const { setValue } = methods;
  const readOnly = mode === 'view';
  const nationVN = valuesField?.nationality?.value === '0';

  return {
    id: {
      type: 'hidden',
    },
    code: {
      type: 'hidden',
    },
    name: {
      type: readOnly ? 'label' : 'text-field',
      placeholder: intl.formatMessage({ id: 'patient.management.dialog.placeholder.name' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.name' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
        validate: (value) => {
          if (!value || value.trim() === '') {
            return intl.formatMessage({ id: 'required' });
          }
        },
      },
      value: valuesField?.name,
    },
    dob: {
      type: readOnly ? 'label' : 'datePicker',
      value: moment(valuesField?.dob, BE_DATE_FORMAT)?.isValid()
        ? moment(valuesField?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
        : '',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.birthday' }),
      propsWrapper: { xs: 2.5 },
      disableFuture: true,
      register: {
        validate: (value) => {
          return !value ||
            (moment(value, BE_DATE_FORMAT, true).isValid() &&
              moment(value, BE_DATE_FORMAT, true).isBefore(moment().endOf('day')))
            ? true
            : intl.formatMessage({ id: 'validation.invalidDate' });
        },
        required: {
          value: !valuesField.age,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      tooltipError: true,
      disabled: (mode !== 'edit' && valuesField.age) || valuesField.age,
      openTo: 'year',
    },
    age: {
      type: readOnly ? 'label' : 'text-field',
      value: moment(valuesField?.dob, BE_DATE_FORMAT)?.isValid()
        ? moment(new Date()).diff(valuesField?.dob, 'year')
        : '',
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.age' }),
      propsWrapper: { xs: 1.5 },
      inputType: 'number',
      register: {
        required: {
          value: !valuesField.dob,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      sx: {
        '& .MuiInputLabel-asterisk': {
          visibility: 'hidden',
        },
      },
      tooltipError: true,
      className: 'label-age-encounter',
      disabled: (mode !== 'edit' && valuesField.dob) || valuesField.dob,
    },
    gender: {
      type: readOnly ? 'label' : 'select',
      value: intl.formatMessage({ id: valuesField?.gender || ' ' }),
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.gender' }),
      propsWrapper: { xs: 4 },
      options: GENDER_OPTIONS,
      register: {
        required: true,
      },
    },
    job: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.job,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.job' }),
      placeholder: intl.formatMessage({ id: 'jobEnter' }),
      propsWrapper: { xs: 4 },
    },
    identifierCode: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.identifierCode,
      placeholder: intl.formatMessage({ id: 'enterIdentification' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.cccd' }),
      propsWrapper: { xs: 6 },
      defaultValue: '',
      inputType: 'number',
    },
    mobilePhone: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.mobilePhone,
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.tel' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
    },
    email: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.email,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.email' }),
      placeholder: intl.formatMessage({ id: 'enterEmail' }),
      propsWrapper: { xs: 6 },
      required: false,
      register: {
        pattern: {
          value: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'emailValid' }),
        },
      },
    },
    jobAddress: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.jobAddress,
      placeholder: intl.formatMessage({ id: 'patient.management.dialog.formLabel.jobAddress' }),
      label: intl.formatMessage({ id: 'jobAddress' }),
      propsWrapper: { xs: 4 },
    },
    ethnic: {
      type: readOnly ? 'label' : 'auto-complete',
      value: valuesField?.ethnic?.label,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.ethnic' }),
      placeholder: intl.formatMessage({ id: 'ethnicEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.ethnicList,
      readOnly: !nationVN,
    },
    nationality: {
      type: readOnly ? 'label' : 'auto-complete',
      value: valuesField?.nationality?.label,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.nationality' }),
      placeholder: intl.formatMessage({ id: 'nationEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.countryList.filter((country) => {
        return valuesField.ethnic?.value === '55' ? country.value !== VN_CODE : true;
      }),
      defaultValue: appState.common.countryList?.find((v) => v.value === VN_CODE),
      onChange: (val: CodeSystem) => {
        if (val.value !== '0') {
          setValue('ethnic', null);
          setValue('province', null);
          setValue('district', null);
        }
      },
    },
    province: {
      type: readOnly ? 'label' : 'auto-complete',
      value: valuesField?.province?.label,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.city' }),
      placeholder: intl.formatMessage({ id: 'provinceEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.provinceData,
      hotKeys: 'alt+t',
      onChange: () => {
        setValue('district', null);
      },
      readOnly: !nationVN,
    },
    district: {
      type: readOnly ? 'label' : 'auto-complete',
      value: valuesField?.district?.label,
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.district' }),
      placeholder: intl.formatMessage({ id: 'districtEnter' }),
      propsWrapper: { xs: 4 },
      options: valuesField.province?.districts,
      hotKeys: 'alt+q',
      readOnly: !nationVN,
    },
    insuranceName: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.insuranceName,
      placeholder: intl.formatMessage({ id: 'enterInsuranceName' }),
      label: intl.formatMessage({ id: 'insuranceName' }),
      propsWrapper: { xs: 6 },
      register: {
        maxLength: {
          value: 255,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
    insuranceNumber: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.insuranceNumber,
      placeholder: intl.formatMessage({ id: 'enterInsuranceNumber' }),
      label: intl.formatMessage({ id: 'insuranceNumber' }),
      propsWrapper: { xs: 6 },
      inputType: 'number',
      register: {
        maxLength: {
          value: 255,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
    address: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.address,
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.address' }),
      propsWrapper: { xs: 8 },
      hotKeys: 'alt+a',
      register: {
        maxLength: {
          value: 255,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
    contactName: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.contactName,
      placeholder: intl.formatMessage({ id: 'contactNameEnter' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.contactFullName' }),
      propsWrapper: { xs: 4 },
    },
    contactTelephone: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.contactTelephone,
      placeholder: intl.formatMessage({ id: 'contactTelephoneEnter' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.contactTel' }),
      propsWrapper: { xs: 4 },
      inputType: 'number',
    },
    contactAddress: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.contactAddress,
      placeholder: intl.formatMessage({ id: 'contactAddressEnter' }),
      label: intl.formatMessage({ id: 'patient.management.dialog.formLabel.contactAddress' }),
      propsWrapper: { xs: 4 },
    },
    note: {
      type: readOnly ? 'label' : 'text-field',
      value: valuesField?.note,
      placeholder: intl.formatMessage({ id: 'noteEnter' }),
      multiline: true,
      rows: 3,
      register: {
        maxLength: {
          value: 1000,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
    cancel: {
      type: 'button',
      variant: 'outlined',
      label: intl.formatMessage({
        id: readOnly ? 'patient.management.dialog.button.close' : 'patient.management.dialog.button.cancel',
      }),
      propsWrapper: { xs: 2 },
      style: { minWidth: 120, marginTop: 20 },
      onClick: onCancel,
    },
    save: {
      type: 'submitButton',
      hidden: readOnly,
      label: intl.formatMessage({ id: 'patient.management.dialog.button.save' }),
      propsWrapper: { xs: 2 },
      style: { minWidth: 120, marginTop: 20 },
    },
  };
};

const ui: IUiFields = ({ formProps, valuesField }) => {
  return [
    {
      id: 'search',
      fields: ['search', 'searchByTel'],
    },
    {
      id: 'patientInfo',
      title: (
        <Box marginBottom={2} display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="patient.management.dialog.title.basicInfo" />
          </Typography>
          {valuesField?.code && (
            <Typography variant="subtitle2" color="textSecondary">
              <FormattedMessage id="patient.management.dialog.title.patientCode" />
              :&nbsp;{valuesField?.code}
            </Typography>
          )}
        </Box>
      ),

      fields: ['patientCode', 'name', 'mobilePhone', 'gender', 'dob', 'age', 'address'],
    },
    {
      id: 'moreInfo1',
      fields: ['email', 'identifierCode', 'insuranceName', 'insuranceNumber'],
    },
    {
      id: 'moreInfo2',
      fields: ['job', 'jobAddress', 'ethnic', 'nationality', 'province', 'district'],
    },
    {
      id: 'contactInfo',
      fields: ['contactName', 'contactTelephone', 'contactAddress'],
    },
    {
      id: 'moreInfo',
      fields: ['note'],
    },
    {
      id: 'footer',
      fields: ['cancel', 'save'],
      propsGridContainer: { sx: { justifyContent: 'end' } },
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { hiddenSearch, state, setState } = formProps;
  return (
    <>
      <Box padding={4} paddingTop={3}>
        {!hiddenSearch && listElement[0]}
        <Box className="box-info">
          {listElement[1]}
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6">
                <FormattedMessage id="moreInfo" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({ ...old, moreInfoCollapse: !old.moreInfoCollapse }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.moreInfoCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.moreInfoCollapse}>
              <Box>{listElement[2]}</Box>
              <Box paddingTop={2}>{listElement[3]}</Box>
            </Collapse>
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2} className="contact-info-person">
              <Typography variant="h6">
                <FormattedMessage id="contactInfoPerson" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    contactInfoPersonCollapse: !old.contactInfoPersonCollapse,
                  }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.contactInfoPersonCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.contactInfoPersonCollapse}>{listElement[4]}</Collapse>
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2} className="contact-info-person">
              <Typography variant="h6">
                <FormattedMessage id="note" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    moreInfoPersonCollapse: !old.moreInfoPersonCollapse,
                  }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.moreInfoPersonCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.moreInfoPersonCollapse}>{listElement[5]}</Collapse>
          </Box>
          <Box marginTop={2}>{listElement[6]}</Box>
        </Box>
      </Box>
    </>
  );
};

const schema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};

export default schema;
